var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"form-container text-black"},[_c('form',[_c('fieldset',[_c('div',{staticClass:"row"},[_c('h2',[_vm._v("Contact Us")]),_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],class:{
                input: true,
                'is-danger': _vm.errors && _vm.errors.name
              },attrs:{"name":"name","placeholder":"First Name*","required":""},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors && _vm.errors.name),expression:"errors && errors.name"}],staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.errors.name)+" ")])]),_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],class:{
                input: true,
                'is-danger': _vm.errors && _vm.errors.email
              },attrs:{"name":"email","placeholder":"Email Address*"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors && _vm.errors.email),expression:"errors && errors.email"}],staticClass:"help is-danger"},[_vm._v(_vm._s(_vm.errors.email))])]),_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.phone),expression:"formData.phone"}],class:{
                input: true,
                'is-danger': _vm.errors && _vm.errors.phone
              },attrs:{"name":"phone","placeholder":"Phone*"},domProps:{"value":(_vm.formData.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "phone", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors && _vm.errors.phone),expression:"errors && errors.phone"}],staticClass:"help is-danger"},[_vm._v(_vm._s(_vm.errors.phone))])]),_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.reference),expression:"formData.reference"}],class:{
                input: true,
                'is-danger': _vm.errors && _vm.errors.reference
              },attrs:{"name":"reference","placeholder":"Booking Ref (Optional)"},domProps:{"value":(_vm.formData.reference)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "reference", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors && _vm.errors.reference),expression:"errors && errors.reference"}],staticClass:"help is-danger"},[_vm._v(_vm._s(_vm.errors.reference))])]),_c('div',{staticClass:"input-wrapper"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],class:{
                input: true,
                'is-danger': _vm.errors && _vm.errors.message
              },attrs:{"name":"message","placeholder":"Message*"},domProps:{"value":(_vm.formData.message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "message", $event.target.value)}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors && _vm.errors.message),expression:"errors && errors.message"}],staticClass:"help is-danger"},[_vm._v(_vm._s(_vm.errors.message))])])]),(_vm.showRecaptcha)?_c('div',{staticClass:"input-wrapper recaptcha"},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":"6LczcxATAAAAAN3f8BdORuhdSbQMMiAMU8mMAy5J"},on:{"verify":function($event){_vm.recaptcha = true},"error":function($event){_vm.recaptcha = false},"expired":function($event){_vm.recaptcha = false}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors && _vm.errors.recaptcha),expression:"errors && errors.recaptcha"}],staticClass:"help is-danger"},[_vm._v(_vm._s(_vm.errors.recaptcha))])],1):_vm._e(),_c('div',{staticClass:"form-buttons"},[_c('button',{staticClass:"button book",on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.loading ? "Please wait..." : "Submit")+" ")])]),(_vm.formResponse)?_c('div',{staticClass:"formResponse.status === 'success' ? 'text-green' : 'text-red'"},[_vm._v(" "+_vm._s(_vm.formResponse.message)+" ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }