<template>
  <div class="home">
    <contact-form-module />
  </div>
</template>

<script>
import contactFormModule from "@/modules/ContactFormModule.vue";
export default {
  components: {
    contactFormModule
  }
};
</script>

<style lang="sass">
@import "../assets/sass/global.sass"
</style>
