<template>
  <div class="content">
    <div class="form-container text-black">
      <form>
        <fieldset>
          <div class="row">
            <h2>Contact Us</h2>

            <div class="input-wrapper">
              <input
                :class="{
                  input: true,
                  'is-danger': errors && errors.name
                }"
                v-model="formData.name"
                name="name"
                placeholder="First Name*"
                required
              />
              <span v-show="errors && errors.name" class="help is-danger">
                {{ errors.name }}
              </span>
            </div>
            <div class="input-wrapper">
              <input
                :class="{
                  input: true,
                  'is-danger': errors && errors.email
                }"
                name="email"
                v-model="formData.email"
                placeholder="Email Address*"
              />
              <span v-show="errors && errors.email" class="help is-danger">{{
                errors.email
              }}</span>
            </div>
            <div class="input-wrapper">
              <input
                :class="{
                  input: true,
                  'is-danger': errors && errors.phone
                }"
                name="phone"
                v-model="formData.phone"
                placeholder="Phone*"
              />
              <span v-show="errors && errors.phone" class="help is-danger">{{
                errors.phone
              }}</span>
            </div>
            <div class="input-wrapper">
              <input
                :class="{
                  input: true,
                  'is-danger': errors && errors.reference
                }"
                name="reference"
                v-model="formData.reference"
                placeholder="Booking Ref (Optional)"
              />
              <span
                v-show="errors && errors.reference"
                class="help is-danger"
                >{{ errors.reference }}</span
              >
            </div>
            <div class="input-wrapper">
              <textarea
                :class="{
                  input: true,
                  'is-danger': errors && errors.message
                }"
                name="message"
                v-model="formData.message"
                placeholder="Message*"
              ></textarea>
              <span v-show="errors && errors.message" class="help is-danger">{{
                errors.message
              }}</span>
            </div>
          </div>
          <div v-if="showRecaptcha" class="input-wrapper recaptcha">
            <vue-recaptcha
              ref="recaptcha"
              sitekey="6LczcxATAAAAAN3f8BdORuhdSbQMMiAMU8mMAy5J"
              @verify="recaptcha = true"
              @error="recaptcha = false"
              @expired="recaptcha = false"
            />
            <span v-show="errors && errors.recaptcha" class="help is-danger">{{
              errors.recaptcha
            }}</span>
          </div>

          <div class="form-buttons">
            <button class="button book" @click.prevent="validateForm">
              {{ loading ? "Please wait..." : "Submit" }}
            </button>
          </div>

          <div
            v-if="formResponse"
            class="formResponse.status === 'success' ? 'text-green' : 'text-red'"
          >
            {{ formResponse.message }}
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import Validate from "@/mixins/Validate";

export default {
  name: "ContactUsModule",
  components: { VueRecaptcha },
  mixins: [Validate],
  data() {
    return {
      formData: {},
      errors: {},
      formResponse: {},
      recaptcha: false,
      loading: false,
      showRecaptcha: true /* used ro reset recaptcha */
    };
  },
  methods: {
    validateForm() {
      if (this.loading) {
        return;
      }

      const { formData } = this;
      this.errors = this.formValidator({
        name: {
          value: formData.name,
          validations: {
            required: "Name is required."
          }
        },
        phone: {
          value: formData.phone,
          validations: {
            required: "Phone is required."
          }
        },
        email: {
          value: formData.email,
          validations: {
            required: "Email is required.",
            email: "Please enter a valid email address"
          }
        },
        message: {
          value: formData.message,
          validations: {
            required: "Message is required."
          }
        },
        recaptcha: {
          value: this.recaptcha,
          validations: {
            required: "Please complete recaptcha."
          }
        }
      });

      if (this.is_empty(this.errors) === false) {
        this.submitForm();
      }
    },
    async submitForm() {
      this.loading = true;
      this.formResponse = "";

      const response = await this.$app.sendEmail(this.formData, "CONTACT_FORM");

      if (response && response.text === "OK") {
        this.formResponse = {
          status: "success",
          message:
            "Thank you for contacting us.We will be in touch with you shortly."
        };

        this.recaptcha = false;
        this.formData = {};
        this.resetRecaptcha();
      } else {
        this.formResponse = {
          status: "error",
          message: "Something went wrong, please try again later."
        };
      }

      this.loading = false;
    },

    resetRecaptcha() {
      this.showRecaptcha = false;

      this.$nextTick(() => {
        this.showRecaptcha = true;
      });
    }
  }
};
</script>
